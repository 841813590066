.wrapper {
  max-width: 700px;
  line-height: 124%;
  padding: 0 32px;
  width: calc(100vw - 64px);
  height: 100%;
  overflow: hidden;
  margin: 12% auto 0 auto;
}

.header {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: -0.24px;
  text-align: center;

  .title {
    text-align: center;
    font-family: "Dela Gothic One", sans-serif;
    font-size: 22px;
    line-height: 32px;
  }

  .subtitle {
    font-size: 20px;
    margin: 16px 0 8px 0;
  }
}

.highlighted {
  font-family: "Dela Gothic One", sans-serif;
  letter-spacing: 0.3px;
  color: #464646;
}

.info {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  margin-left: 12px;
}

.logo {
  width: 85%;
  position: relative;
  bottom: -10px;
  left: 7.5%;
  margin-top: 24px;
}

@media only screen and (min-width: 1000px) {
  .wrapper {
    margin-top: 2%;
  }
}
