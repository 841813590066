.wrapper {
  width: 100vw;
  height: 65vh;
  overflow: hidden;
  padding-top: 35vh;

  .title {
    text-align: center;
    font-family: "Dela Gothic One", sans-serif;
    font-size: 22px;
    line-height: 32px;
  }
}
