.wrapper {
  max-width: 700px;
  line-height: 142%;
  padding: 0 32px;
  width: calc(100vw - 64px);
  height: 100%;
  overflow: hidden;
  margin: 56px auto;

  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: -0.24px;
}

.title {
  text-align: center;
  font-family: "Dela Gothic One", sans-serif;
  font-size: 22px;
  line-height: 32px;
}

.header {
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
}

.block {
  margin: 12px 0;
}

