.wrapper {
  padding: 20px 0;
  background-color: #FFB7D9;

  font-family: "Dela Gothic One", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.2px;

  display: flex;
  flex-direction: row;
  gap: 16px;

  & > div {
    display: flex;
    gap: 16px;
  }
}


@media only screen and (max-width: 600px) {
  .wrapper {
    font-size: 22px;
  }
}
