.form {
    z-index: 5;
    background-color: white;
    margin: 22px 0 8px 0;

    .inner {
        border: 2px solid #464646;
        border-radius: 16px;

        padding: 32px 24px;

        display: flex;
        flex-direction: column;
        gap: 16px;

        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-weight: 300;

        .button {
            flex: 0 0 calc(100% - 60px);
            outline: none;
            border: none;
            border-radius: 42px;
            padding: 15px;
            font-family: "Montserrat", sans-serif;
            color: white;
            background-color: black;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.32px;
            cursor: pointer;
            transition: 0.5s;

            &.disabled {
                cursor: not-allowed;
                background-color: #acacac;
            }
        }
    }

    .highlighted {
        font-family: "Dela Gothic One", sans-serif;
        letter-spacing: 0.3px;
        color: #464646;
    }
}
