.wrapper {
    height: 100vh;
    width: 100vw;

    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0,0,0,0.4);

    overflow: hidden;

    .modal {
        max-width: 650px;
        width: 78%;
        padding: 48px 32px;
        background-color: white;
        border-radius: 12px;
        border: 1.5px solid #5f5f5f;
        text-align: center;

        display: flex;
        flex-direction: column;
        gap: 8px;

        & .emoji {
            font-size: 48px;
        }

        & .title {
            text-align: center;
            font-family: "Dela Gothic One", sans-serif;
            font-size: 22px;
            line-height: 32px;
        }

        & .text {
            font-family: "Montserrat", sans-serif;
            font-size: 16px;
            font-weight: 300;
            line-height: 26px;
            margin-bottom: 16px;
        }

        & .button {
            width: 100%;
            outline: none;
            border: none;
            border-radius: 42px;
            padding: 15px;
            font-family: "Montserrat", sans-serif;
            color: white;
            background-color: black;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.32px;
            cursor: pointer;
        }
    }
}
