.wrapper {
    .options {
        width: 100%;
        display: flex;
        justify-content: start;
        cursor: pointer;
        outline: none;

        position: relative;
        bottom: -1.6px;

        & > .option {
            width: 33%;
            padding: 8px;
            background-color: white;
            border-radius: 12px 12px 0 0;
            border: 1.5px solid #5f5f5f;
            opacity: 0.3;
            display: inline-flex;
            transition: 0.2s;

            & > img {
                margin: 0 auto;
            }
        }

        & > .option.selected {
            opacity: 1;
            border-bottom: 0;
        }
    }

    & .error {
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 300;
        margin-top: 4px;
        margin-left: 4px;
        color: red;
    }

    & > input {
        width: calc(100% - 19px);
        height: 32px;
        padding: 8px;
        font-family: "Montserrat", sans-serif;
        border-radius: 0 0 8px 8px;
        border: 1.5px solid #5f5f5f;
        outline: none;

        &::placeholder {
            opacity: 0.4;
        }
    }
}
